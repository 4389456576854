var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"footer-main"},[(_vm.page !== 'first')?_c('b-row',{staticClass:"justify-content-center px-2 footer-main__row pt w-100"},[_c('b-col',{staticClass:"d-flex justify-content-center mt-md-0 mt-2",attrs:{"xs":"12","md":"6"}},[_c('div',{staticClass:"mt-60"})])],1):_vm._e(),_c('b-row',{staticClass:"justify-content-center px-2 footer-main__row pt w-100"},[_c('b-col',{staticClass:"d-flex justify-content-center mt-md-0 mt-2",attrs:{"xs":"12","md":"6"}},[(
          _vm.currentQuestion == 1 ||
          _vm.currentQuestion == 2 ||
          _vm.currentQuestion == 3 ||
          _vm.currentQuestion == 4 ||
          _vm.currentQuestion == 5 ||
          _vm.currentQuestion == 6 ||
          _vm.currentQuestion == 7 ||
          _vm.currentQuestion == 8
        )?_c('div',{staticClass:"footer-main__text"},[_vm._v(" *Meervoordeel.nl is een officiële partner van Ziggo. Deelname mogelijk tot en met 31 december 2024. Actievoorwaarden van toepassing. ")]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }