<template>
  <div>
    <trustpilot />
  </div>
</template>

<script>
import Trustpilot from "./Trustpilot.vue";

export default {
  name: "MeerOver",
  props: {
    currentQuestion: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {};
  },
  components: {
    Trustpilot,
  },
  methods: {
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  created() {
    document.title = "MeerVoordeel";
  },
};
</script>

<style lang="scss" scoped></style>
