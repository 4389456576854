import axios from "axios";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  postcodeCheckerLoader: false,
  postcodeCheckerId: "",
  postcode: "",
  selectedPrize: "",
  postcodeCheckerName: "",
  activeUsers: {},
  address: {},
  leadLoading: false,
  getErrorResponse: {},
};

const getters = {
  postcodeCheckerLoader: (state) => state.postcodeCheckerLoader,
  postcodeCheckerId: (state) => state.postcodeCheckerId,
  getSelectedPrize: (state) => state.selectedPrize,
  getPostcode: (state) => state.postcode,
  postcodeCheckerName: (state) => state.postcodeCheckerName,
  activeUsers: (state) => state.activeUsers,
  getErrorResponses: (state) => state.getErrorResponse,
};

const actions = {
  async getStreetsByZip({ commit, dispatch }, payload) {
    commit("setLeadLoading", true);
    commit("setPostcode", payload.zip);
    const apiKey = "P6JTU52clKYjZca8";

    try {
      const apiUrl = `https://api.pro6pp.nl/v2/suggest/nl/street?authKey=${apiKey}&postalCode=${payload.zip}`;
      const { data } = await axios.get(apiUrl);
      if (data.length > 0) {
        payload.onSuccess();
        const { settlement } = data[0];

        commit("setAddress", { settlement });
        commit("setLeadLoading", false);
      } else {
        payload.onError();
        commit("setAddress", { settlement: null });
        commit("setLeadLoading", false);
      }
    } catch (error) {
      commit("setAddress");

      commit("setLeadLoading", false);
    }
  },
  async setPostcode({ commit }, payload) {
    commit("setPostcode", payload);
  },

  async postcodeCheckerLead({ commit }, payload) {
    commit("setPostCodeLoader", true);
    commit(
      "setpostcodeCheckerName",
      payload.firstname + " " + payload.lastname
    );
    try {
      if (payload.bootCheck == true) {
        commit("setPostCodeLoader", false);
      } else {
        const response = await leads.postInbaakLead(payload);
        commit("setPostCodeLoader", false);
        const responseid = response?.data.profile.id;
        commit("setId", responseid);
        return {
          success: true,
          profileid: responseid,
        };
      }
    } catch (error) {
      commit("setPostCodeLoader", false);
      if (
        error.response &&
        error.response?.data?.error === "Conflict - duplicate"
      ) {
        return {
          success: true,
          profileid: null,
        };
      } else {
        const errorKey = error.response?.data?.error
          .match(/'([^']+)'/)[0]
          .slice(1, -1);
        commit("setError", { [errorKey]: false });
      }
    }
  },
};

const mutations = {
  setActiveUsers(state, payload) {
    state.activeUsers = payload;
  },
  setPostCodeLoader(state, payload) {
    state.postcodeCheckerLoader = payload;
  },
  setId(state, payload) {
    state.postcodeCheckerId = payload;
  },
  setPostcode(state, payload) {
    state.postcode = payload;
  },
  setpostcodeCheckerName(state, payload) {
    state.postcodeCheckerName = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
  setAddress(state, payload) {
    state.address = payload;
  },
  setLeadLoading(state, payload) {
    state.leadLoading = payload;
  },
  setSelectedPrize(state, payload) {
    state.selectedPrize = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
