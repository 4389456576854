<template>
  <b-container class="footer-main">
    <b-row
      v-if="page !== 'first'"
      class="justify-content-center px-2 footer-main__row pt w-100"
    >
      <b-col xs="12" md="6" class="d-flex justify-content-center mt-md-0 mt-2">
        <div class="mt-60"></div>
      </b-col>
    </b-row>
    <b-row class="justify-content-center px-2 footer-main__row pt w-100">
      <b-col xs="12" md="6" class="d-flex justify-content-center mt-md-0 mt-2">
        <div
          class="footer-main__text"
          v-if="
            currentQuestion == 1 ||
            currentQuestion == 2 ||
            currentQuestion == 3 ||
            currentQuestion == 4 ||
            currentQuestion == 5 ||
            currentQuestion == 6 ||
            currentQuestion == 7 ||
            currentQuestion == 8
          "
        >
          *Meervoordeel.nl is een officiële partner van Ziggo. Deelname mogelijk
          tot en met 31 december 2024. Actievoorwaarden van toepassing.
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      promotionEndDateOffset: 5,
    };
  },
  props: {
    currentQuestion: {
      type: Number,
      default: "",
    },
    page: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedEndDate() {
      const currentDate = new Date();
      const endDate = new Date(
        currentDate.getTime() +
          this.promotionEndDateOffset * 24 * 60 * 60 * 1000
      );

      const options = { day: "2-digit", month: "long", year: "numeric" };
      return endDate.toLocaleDateString("nl-NL", options);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-main {
  font-size: 14px;
  // letter-spacing: 0px;
  color: #06163a;
  font-weight: 400;
  font-family: "DM Sans";

  text-align: center;
  font-family: "DM Sans", sans-serif;
  max-width: 1040px !important;
  padding-top: 50px;
  padding-bottom: 50px;

  &__text {
    color: #c1c1c1 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  a {
    color: #06163a;
    text-decoration: none;
  }

  @media (max-width: 973.99px) {
    font-size: 12px !important;
    padding-top: 0px;
    padding-bottom: 30px;
    &__row {
      padding-top: 0px;
    }
  }
}
.mt-60 {
  margin-top: 3rem !important;
}
</style>
